<template>
    <b-modal title="Prodajalec" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Naziv">
                    <validation-provider #default="{ errors }" name="naziv" rules="required">
                        <b-form-input v-model="object.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Alias">
                    <validation-provider #default="{ errors }" name="alias" rules="required">
                        <b-form-input v-model="object.alias"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="addReseller" :disabled="loading">
                Dodaj <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BSpinner} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, min, max } from '@validations'

    export default {
        components: {
            BSpinner,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                modalActive: false,
                object: {
                    name: '',
                    alias: ''
                },
                required,
                min,
                max
            }
        },
        methods: {
            open() {
                this.object =  {
                    name: '',
                    alias: ''
                }
                this.loading = false
                this.modalActive = true
            },
            async addReseller() {
                const valid = await this.$refs.validation.validate()
                if (!valid) return

                try {
                    this.loading = true
                    const response = await this.$http.post('/api/admin/v1/resellers/', this.object)
                    this.modalActive = false
                    this.$printSuccess('Dodajanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        alias: ''
                    }
                    await this.$router.push({name: 'edit_reseller', params: { reseller_id: response.data.id}})
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        if (error.response.data.error === 'reseller with name or alias exists') this.$printError('Prodajalec s tem nazivom ali aliasom že obstaja')
                        else if (error.response.data.error === 'invalid data') this.$printError('Podatki niso veljavni')
                        else this.$printError('Prišlo je do napake')
                    } else this.$printError('Prišlo je do napake')
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>