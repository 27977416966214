<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" title="Prodajalci">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        Dodaj prodajalca
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="resellers" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-0" @click="openResellerPage(props.row.id)"><feather-icon icon="EditIcon"/></b-button>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <AddResellerModal ref="modal"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddResellerModal from '@/views/Reseller/AddResellerModal.vue'
    import {BButton, BCard, BCol, BOverlay, BRow} from 'bootstrap-vue'

    export default {
        components: {BasicTable, AddResellerModal, BRow, BCol, BCard, BOverlay, BButton},
        data() {
            return {
                resellers: [],
                columns: [
                    {
                        label: 'Naziv',
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Alias',
                        field: 'alias',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Možnosti',
                        field: 'options',
                        displayType: 1,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            openResellerPage(resellerId) {
                this.$router.push({name: 'edit_reseller', params: { reseller_id: resellerId}})
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/admin/v1/resellers/')
                    this.resellers = response.data ?? []
                } catch {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
</style>
